import ImageRenderer from "asset/ImageRenderer";
import i18n from "I18n/index";

export const portfolioColumns = [
    {
        title: i18n.t('pages.portfolio.table.project-name'),
        dataIndex: 'titre',
        key: 'titre',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.portfolio.table.description'),
        dataIndex: 'description',
        key: 'description',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.portfolio.table.lien'),
        dataIndex: 'lien',
        key: 'lien',
      //  sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.portfolio.table.img'),
        dataIndex: 'img',
        key: 'img',
        render: (img, rowData) => {
            return <ImageRenderer imageName={img} alt={rowData.nom} />;
        }
    },




];