import ImageRenderer from "asset/ImageRenderer";
import i18n from "I18n/index";

export const presentationColumns = [
  {
    title: i18n.t('pages.presentation.table.title'),
    dataIndex: 'nom',
    key: 'nom',
    // sorter: (a, b) => a.nom.localeCompare(b.nom),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: i18n.t('pages.presentation.table.description'),
    dataIndex: 'description',
    key: 'description',
    // sorter: (a, b) => a.nom.localeCompare(b.nom),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: i18n.t('pages.presentation.table.img'),
    dataIndex: 'img',
    key: 'img',
    render: (img, rowData) => {
      return <ImageRenderer imageName={img} alt={rowData.nom} />;
    }
  },
];