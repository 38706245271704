import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message } from 'antd';
import useContactData from 'component/Data/ContactData/useContactData';
import UploadFile from 'component/Upload/UploadFile';
import i18n from 'I18n/index';

const ContactModal = ({ visible, onClose, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { createContact, updateContact } = useContactData();
  const [selectedFile, setSelectedFile] = useState(null);
  const { TextArea } = Input;

  useEffect(() => {
    form.resetFields();
    if (initialValues) {
      form.setFieldsValue({
        mail: initialValues.mail,
        numero: initialValues.numero,
        description: initialValues.description,

        fichier: initialValues.fichier || ''
      });
      setSelectedFile(null); // Réinitialiser le fichier sélectionné lors de la modification
    }
  }, [form, initialValues]);


  const handleFinish = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('mail', values.mail);
    formData.append('numero', values.numero);
    formData.append('description', values.description);

    if (selectedFile) {
      formData.append('fichier', selectedFile);
    } else {
      formData.append('fichier', values.fichier);
    }

    try {
      if (initialValues && initialValues.id) {
        await updateContact(initialValues.id, formData);
      } else {
        await createContact(formData);
      }
      onClose();
    } catch (error) {
      message.error(i18n.t('error.contact.save'));
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      open={visible}
      title={initialValues ? i18n.t('pages.contact.form.edit') : i18n.t('pages.contact.form.add')}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {i18n.t('button.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
          {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item name="id" label="ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="mail"
          label={i18n.t('pages.contact.table.email')}
          rules={[{ required: true, message: i18n.t('pages.contact.placeholder.email'), type: 'email'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="numero"
          label={i18n.t('pages.contact.table.phone')}
          rules={[
            { required: true, message: ('pages.contact.placeholder.phone'), pattern: /^\+33[-\s]?[0-9]{1}[-\s]?[0-9]{2}[-\s]?[0-9]{2}[-\s]?[0-9]{2}[-\s]?[0-9]{2}$/ }
          ]}
        >
          <Input type='tel' placeholder="+33 6 12 34 56 78" />
        </Form.Item>
        <Form.Item
          name="description"
          label={i18n.t('pages.contact.table.description')}
          rules={[{ required: true, message: ('pages.contact.placeholder.description') }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        {!initialValues || !initialValues.fichier ? (
          <Form.Item label={i18n.t('pages.contact.table.fileD')}
          rules={[{ required: true, message: i18n.t('pages.contact.placeholder.file') }]}

          >
            <UploadFile onChange={handleFileChange} />
          </Form.Item>
        ) : (
          <Form.Item
            name="fichier"
            label={i18n.t('pages.contact.table.url')}
          >
            <Input placeholder={i18n.t('pages.contact.placeholder.url')} disabled />
            </Form.Item>
        )}
      </Form>
    </Modal >
  );
};

ContactModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    mail: PropTypes.string,
    numero: PropTypes.string,
    fichier: PropTypes.string,

  }),
};

ContactModal.defaultProps = {
  initialValues: null,
};

export default ContactModal;
