import { useReducer, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { baseURL } from 'Ressources';
import i18n from 'I18n/index';

const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';

const initialState = {
  loading: false,
  data: {
    competences: [],
    experiences: [],
    formations: [],
    presentation: [],
    portfolio: [],
  },
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return { ...state, loading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

const useDashboardData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: FETCH_START });
      try {
        const response = await axios.get(`${baseURL}/admin/dashboard`);
        if (response.status === 200) {
          dispatch({ type: FETCH_SUCCESS, payload: response.data });
        } else {
          throw new Error(i18n.t('error.dashboard.fetch'));
        }
      } catch (error) {
        dispatch({ type: FETCH_ERROR, error: i18n.t('error.dashboard.fetch')});
        message.error(i18n.t('error.dashboard.fetch'));
      }
    };
    fetchData();
  }, []);

  return state;
};

export default useDashboardData;
