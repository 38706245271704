import i18n from "I18n/index";

export const contactColumns = [
    {
        title: i18n.t('pages.contact.table.email'),
        dataIndex: 'mail',
        key: 'mail',
      //  sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.contact.table.phone'),
        dataIndex: 'numero',
        key: 'numero',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.contact.table.description'),
        dataIndex: 'description',
        key: 'description',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.contact.table.file'),
        dataIndex: 'fichier',
        key: 'fichier',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    
];

