import { useState, useEffect } from 'react';
import { Spin, message, Button, Alert } from 'antd';
import axios from 'axios';
import { baseURL } from 'Ressources';
import ImageRenderer from 'asset/ImageRenderer';
import i18n from 'I18n/index';

const Portfolio = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [portfolioData, setPortfolioData] = useState({
        portfolios: [],
    });

    const fetchPorfolioData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseURL}/portfolio`);
            if (response.status === 200) {
                const portfolios = response.data;
                setPortfolioData({ portfolios });
            } else {
                throw new Error(i18n.t('errors.data.default'));
            }
        } catch (error) {
            setError(i18n.t('errors.data.no_data'));
            message.error(i18n.t('errors.data.default'));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPorfolioData();
    }, []);

    return (
        <div className="portfolio-container">
            {loading ? (
                <Spin spinning={true} />
            ) : error ? (
                <Alert message="Erreur" description={error} type="error" showIcon />
            ) : (
                <section className="portfolio-section">
                    <h2 className="portfolio-header">{i18n.t('title.portfolio')}</h2>
                    <div className="portfolio-grid">
                        {portfolioData.portfolios.map((portfolio, index) => (
                            <div key={index} className="portfolio-item">
                                <h3 className="portfolio-title">{portfolio.titre}</h3>
                                <ImageRenderer imageName={portfolio.img} alt={portfolio.nom} />
                                <p className="portfolio-description">{portfolio.description}</p>
                                <Button type="primary" href={portfolio.lien} target="_blank">
                                    {i18n.t('button.visit')}
                                </Button>
                            </div>
                        ))}
                    </div>
                </section>
            )}
        </div>
    );
};

export default Portfolio;
