import React, { useState, useEffect } from 'react';
import { getImage } from './imageLoader';

const ImageRenderer = ({ imageName, alt }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const loadImg = async () => {
            const src = await getImage(imageName);
            setImageSrc(src);
        };

        loadImg();
    }, [imageName]);

    if (!imageSrc) {
        return <div>Loading...</div>;
    }

    return <img className='base-item-image' src={imageSrc} alt={alt} />;
};

export default ImageRenderer;
