import { useReducer, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { baseAdminUrl } from 'Ressources';
import i18n from 'I18n/index';

// Actions
const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const DELETE_SUCCESS = 'DELETE_SUCCESS';

// Initial State
const initialState = {
  loading: false,
  data: {
    presentations: [],
  },
  error: null,
};

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return { ...state, loading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_ERROR:
      return { ...state, loading: false, error: action.error };
    case CREATE_SUCCESS:
      return { ...state, data: { ...state.data, presentations: [...state.data.presentations, action.payload] } };
    case UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          presentations: state.data.presentations.map((comp) =>
            comp.id === action.payload.id ? action.payload : comp
          ),
        },
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          presentations: state.data.presentations.filter((comp) => comp.id !== action.payload.id),
        },
      };
    default:
      return state;
  }
};

// Custom Hook
const usePresentationData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: FETCH_START });
      try {
        const response = await axios.get(`${baseAdminUrl}/presentation/`);
        if (response.status === 200) {
          const presentations = Array.isArray(response.data) ? response.data : [];
          dispatch({ type: FETCH_SUCCESS, payload: { presentations } });
        } else {
          throw new Error(i18n.t('error.presentation.fetch'));
        }
      } catch (error) {
        dispatch({ type: FETCH_ERROR, error:i18n.t('error.presentation.fetch')});
        message.error(i18n.t('error.presentation.fetch'));
      }
    };

    fetchData();
  }, []);

  const createPresentation = async (presentationData) => {
    try {
      const response = await axios.post(`${baseAdminUrl}/presentation/ajouter`, presentationData);
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: CREATE_SUCCESS, payload: response.data });
        message.success(i18n.t('success.presentation.create'));
      } else {
        throw new Error(i18n.t('error.presentation.create'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updatePresentation = async (id, presentationData) => {
    try {
      const requestBody = new URLSearchParams(presentationData).toString();

      const response = await axios.put(`${baseAdminUrl}/presentation/modifier/${id}`, requestBody, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': requestBody.length
        }
      });
      if (response.status === 200) {
        dispatch({ type: UPDATE_SUCCESS, payload: response.data });
        message.success(i18n.t('success.presentation.update'));
      } else {
        throw new Error(i18n.t('error.presentation.update'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };


  const deletePresentation = async (id) => {
    try {
      const response = await axios.delete(`${baseAdminUrl}/presentation/supprimer/${id}`);
      if (response.status === 200) {
        dispatch({ type: DELETE_SUCCESS, payload: { id } });
        message.success(i18n.t('success.presentation.delete'));
      } else {
        throw new Error(i18n.t('error.presentation.delete'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return { ...state, createPresentation, updatePresentation, deletePresentation };
};

export default usePresentationData;
