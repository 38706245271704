import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message } from 'antd';
import usePresentationData from 'component/Data/PresentationData/usePresentationData';
import UploadImg from 'component/Upload/UploadImg';
import i18n from 'I18n/index';

const PresentationModal = ({ visible, onClose, initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { createPresentation, updatePresentation } = usePresentationData();
    const [selectedFile, setSelectedFile] = useState(null);
    const { TextArea } = Input;

    useEffect(() => {
        form.resetFields();
        if (initialValues) {
            form.setFieldsValue({
                nom: initialValues.nom,
                description: initialValues.description,

                img: initialValues.img || ''
            });
            setSelectedFile(null); // Réinitialiser le fichier sélectionné lors de la modification
        }
    }, [form, initialValues]);


    const handleFinish = async (values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('nom', values.nom);
        formData.append('description', values.description);

        if (selectedFile) {
            formData.append('img', selectedFile);
        } else {
            formData.append('img', values.img);
        }

        try {
            if (initialValues && initialValues.id) {
                await updatePresentation(initialValues.id, formData);
            } else {
                await createPresentation(formData);
            }
            onClose();
        } catch (error) {
            message.error(i18n.t('error.presentation.save'));
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (file) => {
        setSelectedFile(file);
    };

    return (
        <Modal
            open={visible}
            title={initialValues ? i18n.t('pages.presentation.form.edit') : i18n.t('pages.presentation.form.add')}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {i18n.t('button.cancel')}
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
                    {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item name="id" label="ID" hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="nom"
                    label={i18n.t('pages.presentation.table.title')}
                    rules={[{ required: true, message: i18n.t('pages.presentation.placeholder.title') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={i18n.t('pages.presentation.table.description')}
                    rules={[{ required: true, message: i18n.t('pages.presentation.placeholder.description') }]}
                >
                    <TextArea rows={4} />
                </Form.Item>
                {!initialValues || !initialValues.img ? (
                    <Form.Item label={i18n.t('pages.presentation.table.imgD')}
                        rules={[{ required: true, message: i18n.t('pages.presentation.placeholder.img') }]}>
                        <UploadImg onChange={handleFileChange} />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="img"
                        label={i18n.t('pages.presentation.table.url')}
                    >
                        <Input placeholder={i18n.t('pages.presentation.placeholder.url')} disabled />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

PresentationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        nom: PropTypes.string,
        description: PropTypes.string,
        img: PropTypes.string,
    }),
};

PresentationModal.defaultProps = {
    initialValues: null,
};

export default PresentationModal;
