import i18n from 'I18n/index';
import React from 'react';

const Copyright = () => {
    return (
        <div className="footer-copyright">
            <p>&copy; {new Date().getFullYear()} {i18n.t('pages.copyright')}</p>
        </div>
    );
};

export default Copyright;
