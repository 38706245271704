import HttpService from "./htttp.service";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_URL;

const login = async (credentials) => {
  const userData = { 
    username: credentials?.username, 
    password: credentials?.password 
  };

  try {
    const response = await axios.post(API_URL + "/login_check", userData);
    if (response.data.accessToken) {
      sessionStorage.setItem("token", JSON.stringify(response.data));
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "An error occurred during login.");
  }
};


const logout = () => {
  sessionStorage.removeItem("token");
};

const register = async (credentials) => {
  const registerEndpoint = 'register';
  return await HttpService.post(registerEndpoint, credentials);
};

const forgotPassword = async (payload) => {
  const forgotPasswordEndpoint = 'password-forgot';
  return await HttpService.post(forgotPasswordEndpoint, payload);
};

const resetPassword = async (credentials) => {
  const resetPasswordEndpoint = 'password-reset';
  return await HttpService.post(resetPasswordEndpoint, credentials);
};

const getProfile = async () => {
  const getProfileEndpoint = 'me';
  return await HttpService.get(getProfileEndpoint);
};

const getCompetencePro = async () => {
  const getCompetenceProEndpoint = 'competencePro';
  return await HttpService.get(getCompetenceProEndpoint);
};

const updateProfile = async (newInfo) => {
  const updateProfileEndpoint = "me";
  return await HttpService.patch(updateProfileEndpoint, newInfo);
};

export default {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  getProfile,
  getCompetencePro,
  updateProfile
};
