import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, ConfigProvider } from 'antd';
import { presentationColumns } from 'component/Value/TableData/PresentationData/PresentationTableData';
import usePresentationData from 'component/Data/PresentationData/usePresentationData';
import TableSection from 'component/Value/Table/TableSection';
import PresentationModal from 'component/Modal/PresentationModal';
import { TinyColor } from '@ctrl/tinycolor';
import i18n from 'I18n/index';

const Container = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
`;

const deletedColors = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
const upgradeColors = ['#6253E1', '#04BEFE'];

const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());

const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const Presentation = () => {
  const { loading, data, createPresentation, updatePresentation, deletePresentation } = usePresentationData();
  const [visible, setVisible] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState(null);

  const showModal = (presentation = null) => {
    setSelectedPresentation(presentation);
    setVisible(true);
  };

  const closeModal = () => {
    setSelectedPresentation(null);
    setVisible(false);
  };

  const handleSubmit = async (presentationData) => {
    if (selectedPresentation) {
      try {
        await updatePresentation(selectedPresentation.id, presentationData);
      } catch (error) {
        // Error messages are handled within usePresentationData
      }
    } else {
      try {
        await createPresentation(presentationData);
      } catch (error) {
        // Error messages are handled within usePresentationData
      }
    }
    closeModal();
  };

  const handleDelete = async (record) => {
    if (record && record.id) {
      try {
        await deletePresentation(record.id);
      } catch (error) {
        // Error messages are handled within usePresentationData
      }
    } else {
      message.error(i18n.t('error.selectD')
      );
    }
  };

  const presentations = data ? data.presentations : [];

  return (
    <Container>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
        {i18n.t('button.new')}

      </Button>
      <TableSection
        title={i18n.t('title.presentation')}
        dataSource={presentations}
        columns={[
          ...presentationColumns,
          {
            title: i18n.t('title.action'),
            key: 'action',
            render: (_, record) => (
              <span>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg,  ${upgradeColors.join(', ')})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(upgradeColors).join(', ')})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(upgradeColors).join(', ')})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button onClick={() => showModal(record)} type="primary" size="large">
                    {i18n.t('button.update')}

                  </Button>
                </ConfigProvider>
                {' | '}
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg,  ${deletedColors.join(', ')})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(deletedColors).join(', ')})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(deletedColors).join(', ')})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button onClick={() => handleDelete(record)} type="primary" size="large">
                    {i18n.t('button.delete')}

                  </Button>
                </ConfigProvider>
              </span>
            ),
          },
        ]}
        loading={loading}
      />
      <PresentationModal
        visible={visible}
        onClose={closeModal}
        initialValues={selectedPresentation}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default Presentation;
