import i18n from "I18n/index";

export const formationColumns = [
    {
        title: i18n.t('pages.formation.table.title'),
        dataIndex: 'titre',
        key: 'titre',
       // sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.centre'),
        dataIndex: 'centre',
        key: 'centre',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.type'),
        dataIndex: 'type',
        key: 'type',
       // sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.description'),
        dataIndex: 'description',
        key: 'description',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.debutM'),
        dataIndex: 'mois_debut',
        key: 'mois_debut',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.debutA'),
        dataIndex: 'annee_debut',
        key: 'annee_debut',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.finM'),
        dataIndex: 'mois_fin',
        key: 'mois_fin',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.formation.table.finA'),
        dataIndex: 'annee_fin',
        key: 'annee_fin',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    
];