import React from 'react';
import ImageRenderer from 'asset/ImageRenderer';
import i18n from 'I18n/index';

export const competenceColumns = [
  {
    title: i18n.t('pages.competence.table.name'),
    dataIndex: 'nom',
    key: 'nom',
   // sorter: (a, b) => a.nom.localeCompare(b.nom),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: i18n.t('pages.competence.table.img'),
    dataIndex: 'img',
    key: 'img',
    render: (img, rowData) => {
      return <ImageRenderer imageName={img} alt={rowData.nom} />;
    }
  },
];
