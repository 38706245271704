import Footer from "views/Footer";
import AuthProvider from "./context";
import Routes from "./navigation";
import Nav from "./navigation/Menu"

function App() {
  return (
      <AuthProvider>
        <Nav />
        <Routes />
        <Footer />
      </AuthProvider>
  );
}

export default App;
