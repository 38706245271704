import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import AuthService from "services/auth-service";
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import 'Theme/login.scss';
import i18n from 'I18n/index';

const Connexion = () => {
  const navigate = useNavigate();
  const [credentialsError, setCredentialsError] = useState(null);
  const { setToken } = useAuth();

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    usernameError: false,
    passwordError: false,
  });

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async () => {
    const usernameFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputs.username.trim().length === 0 || !inputs.username.trim().match(usernameFormat)) {
      setErrors({ ...errors, usernameError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    const userData = {
      username: inputs.username,
      password: inputs.password,
    };

    try {
      const response = await AuthService.login(userData); // Utilisation directe de la méthode login de AuthService
      const handleLogin = () => {
        setToken(response);
        navigate("/admin/home", { replace: true });
      };

      setTimeout(() => {
        handleLogin();
      }, 3 * 1000);

      // Rediriger vers la page souhaitée après une connexion réussie
    } catch (error) {
      setCredentialsError(error.message || "An error occurred during login.");
      console.error("Login error:", error);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">{i18n.t('login')}</h2>
      <Form
        name="basic"
        onFinish={submitHandler}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
          validateStatus={errors.usernameError ? 'error' : ''}
          help={errors.usernameError ? 'Please input a valid username!' : null}
        >
          <Input className="input-field" name="username" onChange={changeHandler} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          validateStatus={errors.passwordError ? 'error' : ''}
          help={errors.passwordError ? 'Password must be at least 6 characters long!' : null}
        >
          <Input.Password className="input-field" name="password" onChange={changeHandler} />
        </Form.Item>

        <Form.Item
        >
          <Button type="primary" htmlType="submit" className="submit-button">
            {i18n.t('button.login')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Connexion;
