import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const ProtectedRoute = ({ element }) => {
  const { token } = useAuth();

  // Vérifie si l'utilisateur est authentifié
  if (!token) {
    // S'il n'est pas authentifié, redirige vers la page de connexion
    return <Navigate to="/" />;
  }

  // Si l'utilisateur est authentifié, affiche les routes enfants
  return element;
};

export default ProtectedRoute;
