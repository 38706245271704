import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, ConfigProvider } from 'antd';
import { portfolioColumns } from 'component/Value/TableData/PortfolioData/PortfolioTableData';
import usePortfolioData from 'component/Data/PortfolioData/usePortfolioData';
import TableSection from 'component/Value/Table/TableSection';
import { TinyColor } from '@ctrl/tinycolor';
import PortfolioModal from 'component/Modal/PortfolioModal';
import i18n from 'I18n/index';

const Container = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
`;

const deletedColors = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
const upgradeColors = ['#6253E1', '#04BEFE'];

const getHoverColors = (colors) =>
    colors.map((color) => new TinyColor(color).lighten(5).toString());

const getActiveColors = (colors) =>
    colors.map((color) => new TinyColor(color).darken(5).toString());

const Portfolio = () => {
    const { loading, data, createPortfolio, updatePortfolio, deletePortfolio } = usePortfolioData();
    const [visible, setVisible] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);

    const showModal = (portfolio = null) => {
        setSelectedPortfolio(portfolio);
        setVisible(true);
    };

    const closeModal = () => {
        setSelectedPortfolio(null);
        setVisible(false);
    };

    const handleSubmit = async (portfolioData) => {
        if (selectedPortfolio) {
            try {
                await updatePortfolio(selectedPortfolio.id, portfolioData);
            } catch (error) {
                // Error messages are handled within usePortfolioData
            }
        } else {
            try {
                await createPortfolio(portfolioData);
            } catch (error) {
                // Error messages are handled within usePortfolioData
            }
        }
        closeModal();
    };

    const handleDelete = async (record) => {
        if (record && record.id) {
            try {
                await deletePortfolio(record.id);
            } catch (error) {
                // Error messages are handled within usePortfolioData
            }
        } else {
            message.error(i18n.t('error.selectD'))
        }
    };

    const portfolios = data ? data.portfolios : [];

    return (
        <Container>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                {i18n.t('button.new')}
            </Button>
            <TableSection
                title={i18n.t('title.portfolio')}

                dataSource={portfolios}
                columns={[
                    ...portfolioColumns,
                    {
                        title: i18n.t('title.action'),
                        key: 'action',
                        render: (_, record) => (
                            <span>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Button: {
                                                colorPrimary: `linear-gradient(90deg,  ${upgradeColors.join(', ')})`,
                                                colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(upgradeColors).join(', ')})`,
                                                colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(upgradeColors).join(', ')})`,
                                                lineWidth: 0,
                                            },
                                        },
                                    }}
                                >
                                    <Button onClick={() => showModal(record)} type="primary" size="large">
                                        {i18n.t('button.update')}

                                    </Button>
                                </ConfigProvider>
                                {' | '}
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Button: {
                                                colorPrimary: `linear-gradient(90deg,  ${deletedColors.join(', ')})`,
                                                colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(deletedColors).join(', ')})`,
                                                colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(deletedColors).join(', ')})`,
                                                lineWidth: 0,
                                            },
                                        },
                                    }}
                                >
                                    <Button onClick={() => handleDelete(record)} type="primary" size="large">
                                        {i18n.t('button.delete')}

                                    </Button>
                                </ConfigProvider>
                            </span>
                        ),
                    },
                ]}
                loading={loading}
            />
            <PortfolioModal
                visible={visible}
                onClose={closeModal}
                initialValues={selectedPortfolio}
                onSubmit={handleSubmit}
            />
        </Container>
    );
};

export default Portfolio;
