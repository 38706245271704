import React, { useState } from "react";
import { Navbar, Nav, Container, NavItem, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineCode, AiOutlineFile, AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { useAuth } from "context/AuthContext";
import "Theme/nav.scss";
import i18n from "I18n/index";

function NavBar() {
  const [expand, setExpand] = useState(false);
  const [navColour, setNavColour] = useState(false);
  const { token } = useAuth(); // Obtenez le jeton d'authentification depuis le contexte

  const scrollHandler = () => {
    setNavColour(window.scrollY >= 20);
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          {/* Contenu de la marque de la barre de navigation */}
        </Navbar.Brand>


        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {!token ? (
              <>
                <NavItem>
                  <NavLink as={Link} to="/" onClick={() => setExpand(false)}>
                    <AiOutlineUser style={{ marginBottom: "2px" }} />{i18n.t('nav.home')}

                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/cv" onClick={() => setExpand(false)}>
                    <AiOutlineFile style={{ marginBottom: "2px" }} /> {i18n.t('nav.cv')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/portfolio" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.portfolio')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="mailto:alexis1722086@gmail.com" onClick={() => setExpand(false)}>
                    <AiOutlineMail style={{ marginBottom: "2px" }} /> {i18n.t('nav.contact')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/login" onClick={() => setExpand(false)}>
                    <AiOutlineUser style={{ marginBottom: "2px" }} /> {i18n.t('nav.login')}
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem>
                  <NavLink as={Link} to="/admin/home" onClick={() => setExpand(false)}>
                    <AiOutlineUser style={{ marginBottom: "2px" }} /> {i18n.t('nav.dashboard')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/presentation" onClick={() => setExpand(false)}>
                    <AiOutlineFile style={{ marginBottom: "2px" }} /> {i18n.t('nav.presentation')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/competence" onClick={() => setExpand(false)}>
                    <AiOutlineFile style={{ marginBottom: "2px" }} /> {i18n.t('nav.competence')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/portfolio" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.portfolio')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/experience" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.experience')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/formation" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.formation')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/contact" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.contact')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/admin/footer" onClick={() => setExpand(false)}>
                    <AiOutlineCode style={{ marginBottom: "2px" }} /> {i18n.t('nav.footer')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink as={Link} to="/logout" onClick={() => setExpand(false)}>
                    <AiOutlineUser style={{ marginBottom: "2px" }} /> {i18n.t('nav.logout')}
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
