import axios from 'axios';

export const getImage = async (imageName) => {
    try {
        const response = await axios.get(`/image/${imageName}`, {
            responseType: 'blob',
        });

        const blob = response.data;
        return await convertBlobToBase64(blob);
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};

const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};
