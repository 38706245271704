import React from 'react';
import { Table, Spin } from 'antd';
import styled from 'styled-components';

const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionHeader = styled.h2`
  font-size: 24px;
  color: #1890ff;
`;

const TableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const TableSection = ({ title, dataSource, columns, loading }) => (
  <Section>
    <SectionHeader>{title}</SectionHeader>
    <TableContainer>
      <Spin spinning={loading}>
      <Table dataSource={dataSource} columns={columns} loading={loading} rowKey="id" />
      </Spin>
    </TableContainer>
  </Section>
);

export default TableSection;
