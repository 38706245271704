import i18n from "I18n/index";

export const experienceColumns = [
    {
        title: i18n.t('pages.experience.table.company'),
        dataIndex: 'nom',
        key: 'nom',
      //  sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.poste'),
        dataIndex: 'poste',
        key: 'poste',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    
    {
        title: i18n.t('pages.experience.table.lieu'),
        dataIndex: 'lieu',
        key: 'lieu',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.description'),
        dataIndex: 'description',
        key: 'description',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.debutM'),
        dataIndex: 'mois_debut',
        key: 'mois_debut',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.debutA'),
        dataIndex: 'annee_debut',
        key: 'annee_debut',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.finM'),
        dataIndex: 'mois_fin',
        key: 'mois_fin',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.experience.table.finA'),
        dataIndex: 'annee_fin',
        key: 'annee_fin',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    
];

