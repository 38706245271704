import { useState, useEffect } from 'react';
import { Spin, message, Alert } from 'antd';
import axios from 'axios';
import { baseURL } from 'Ressources'; // Assurez-vous que baseURL est défini correctement
import i18n from 'I18n/index';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState(null);
    const [error, setError] = useState(null);

    const fetchContactData = async () => {
        setLoading(true);
        setError(null); // Réinitialiser l'erreur au début de la nouvelle requête
        try {
            const response = await axios.get(`${baseURL}/contact`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true, // Si vous avez besoin d'envoyer des cookies avec la requête
            });
            if (response.status === 200) {
                const contact = response.data; // Supposons que vous recevez un objet contact unique
                setContactData(contact);
            } else {
                throw new Error(i18n.t('errors.data.default'));
            }
        } catch (error) {
            setError(i18n.t('errors.data.no_data'));
            message.error(i18n.t('errors.data.default'));
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchContactData();
    }, []);

    return (
        <div >
            {loading ? (
                <Spin spinning={true} />
            ) : error ? (
                <Alert message="Erreur" description={error} type="error" showIcon />
            ) : contactData ? (
                <section className="base-section-contact">
                    <div className="contact-flex-container">
                        <div className="contact-item">
                            <p className="contact-item-description">{contactData[0].description}</p>
                            <p className="contact-item-description">{i18n.t('pages.contact.table.phone')} : {contactData[0].numero}</p>
                            <p className="contact-item-description">
                                {i18n.t('pages.contact.table.email')} :
                                <a href={`mailto:${contactData[0].mail}`}> {contactData[0].mail}</a>
                            </p>
                            <p className="contact-item-description">{i18n.t('pages.contact.table.cv')} :
                                <a
                                    href={`${baseURL}/files/${contactData[0].fichier}`}
                                    download
                                >
                                    {i18n.t('pages.contact.table.download')}
                                </a></p>
                        </div>
                    </div>
                </section>
            ) : (
                <p>{i18n.t('pages.no_data')}</p>
            )}
        </div>
    );
};

export default Contact;
