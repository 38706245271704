import ImageRenderer from "asset/ImageRenderer";
import i18n from "I18n/index";

export const footerColumns = [
    {
        title: i18n.t('pages.footer.table.title'),
        dataIndex: 'titre',
        key: 'titre',
      //  sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.footer.table.lien'),
        dataIndex: 'lien',
        key: 'lien',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: i18n.t('pages.footer.table.img'),
        dataIndex: 'img',
        key: 'img',
        //sorter: (a, b) => a.nom.localeCompare(b.nom),
        render: (img, rowData) => {
            return <ImageRenderer imageName={img} alt={rowData.nom} />;
          }    },

];

