import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import moment from 'moment';
import 'moment/locale/fr';

// Configurations
i18n
  .use(LanguageDetector)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    react: {
      useSuspense: true, // 'wait' is deprecated, use 'useSuspense'
    },
    lng: 'fr', // Default language
    resources: {
      fr: {
        translation: require('./languages/fr.json'),
      },
      en: {
        translation: require('./languages/en.json'),
      }
    },
    // Optionally set fallbackLng and other settings
    fallbackLng: 'fr',
    debug: false,
  })
  .then(() => {
    // Determine the locale after initialization
    const locale = i18n.language || 'fr';
    // Set the locale for moment.js
    moment.locale(locale);
  })
  .catch((err) => {
    console.error('i18n initialization failed:', err);
    // Fallback locale
    moment.locale('fr');
  });

// Export the configured i18n instance
export default i18n;
