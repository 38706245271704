import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message } from 'antd';
import useCompetenceData from 'component/Data/CompetenceData/useCompetenceData';
import UploadImg from 'component/Upload/UploadImg';
import i18n from 'I18n/index';

const CompetenceModal = ({ visible, onClose, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { createCompetence, updateCompetence } = useCompetenceData();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    form.resetFields();
    if (initialValues) {
      form.setFieldsValue({
        nom: initialValues.nom,
        img: initialValues.img || ''
      });
      setSelectedFile(null); // Réinitialiser le fichier sélectionné lors de la modification
    }
  }, [form, initialValues]);


  const handleFinish = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('nom', values.nom);
    if (selectedFile) {
      formData.append('img', selectedFile);
    } else {
      formData.append('img', values.img);
    }

    try {
      if (initialValues && initialValues.id) {
        await updateCompetence(initialValues.id, formData);
      } else {
        await createCompetence(formData);
      }
      onClose();
    } catch (error) {
      message.error(i18n.t('error.competence.save'));
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      open={visible}
      title={initialValues ? i18n.t('pages.competence.form.edit') : i18n.t('pages.competence.form.add')}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {i18n.t('button.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
          {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item name="id" label="ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="nom"
          label={i18n.t('pages.competence.table.name')}
          rules={[{ required: true, message: i18n.t('pages.competence.placeholder.name') }]}
          >
          <Input />
        </Form.Item>
        {!initialValues || !initialValues.img ? (
          <Form.Item label={i18n.t('pages.competence.table.imgD')}
            rules={[{ required: true, message: i18n.t('pages.competence.placeholder.img') }]}
          >
            <UploadImg onChange={handleFileChange} />
          </Form.Item>
        ) : (
          <Form.Item
            name="img"
            label={i18n.t('pages.competence.table.url')}
          >
            <Input placeholder={i18n.t('pages.competence.placeholder.url')} disabled />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

CompetenceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    nom: PropTypes.string,
    img: PropTypes.string,
  }),
};

CompetenceModal.defaultProps = {
  initialValues: null,
};

export default CompetenceModal;
