import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message } from 'antd';
import useFooterData from 'component/Data/FooterData/useFooterData';
import UploadImg from 'component/Upload/UploadImg';
import i18n from 'I18n/index';

const FooterModal = ({ visible, onClose, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { createFooter, updateFooter } = useFooterData();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    form.resetFields();
    if (initialValues) {
      form.setFieldsValue({
        titre: initialValues.titre,
        lien: initialValues.lien,
        img: initialValues.img || ''
      });
      setSelectedFile(null); // Réinitialiser le img sélectionné lors de la modification
    }
  }, [form, initialValues]);


  const handleFinish = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('titre', values.titre);
    formData.append('lien', values.lien);

    if (selectedFile) {
      formData.append('img', selectedFile);
    } else {
      formData.append('img', values.img);
    }

    try {
      if (initialValues && initialValues.id) {
        await updateFooter(initialValues.id, formData);
      } else {
        await createFooter(formData);
      }
      onClose();
    } catch (error) {
      message.error(i18n.t('error.footer.save'));
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      open={visible}
      title={initialValues ? i18n.t('pages.footer.form.edit') : i18n.t('pages.footer.form.add')}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {i18n.t('button.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
          {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item name="id" label="ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="titre"
          label={i18n.t('pages.footer.table.title')}
          rules={[{ required: true, message: i18n.t('pages.footer.placeholder.title') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lien"
          label={i18n.t('pages.footer.table.lien')}
          rules={[{ required: true, message: i18n.t('pages.footer.placeholder.lien') }]}
        >
          <Input />
        </Form.Item>
        {!initialValues || !initialValues.img ? (
          <Form.Item label={i18n.t('pages.footer.table.imgD')}
          >
            <UploadImg onChange={handleFileChange} />
          </Form.Item>
        ) : (
          <Form.Item
            name="img"
            label={i18n.t('pages.footer.table.url')}
            >
              <Input placeholder={i18n.t('pages.footer.placeholder.url')} disabled />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

FooterModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    nom: PropTypes.string,
    img: PropTypes.string,
  }),
};

FooterModal.defaultProps = {
  initialValues: null,
};

export default FooterModal;
