import axios from "axios";

// Définition de l'URL de l'API à partir de la variable d'environnement REACT_APP_API_URL
const API_URL = process.env.REACT_APP_API_URL;

// Configuration de l'URL de base pour toutes les requêtes axios
axios.defaults.baseURL = API_URL;

class HttpService {
  constructor() {
    // Création d'une instance axios pour l'utilisation dans cette classe
    this._axios = axios.create();
  }

  // Ajout d'un intercepteur de requête
  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  // Ajout d'un intercepteur de réponse
  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  // Méthode pour effectuer une requête GET
  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  // Méthode pour effectuer une requête POST
  post = async (url, data) => await this.request(this.getOptionsConfig("post", url, data));

  // Méthode pour effectuer une requête PUT
  put = async (url, data) => await this.request(this.getOptionsConfig("put", url, data));

  // Méthode pour effectuer une requête PATCH
  patch = async (url, data) => await this.request(this.getOptionsConfig("patch", url, data));

  // Méthode pour effectuer une requête DELETE
  delete = async (url) => await this.request(this.getOptionsConfig("delete", url));

  // Méthode pour générer la configuration des options de requête
  getOptionsConfig = (method, url, data) => {
    return { method, url, data, headers: { 'Content-Type': 'application/json' } };
  };

  // Méthode pour envoyer une requête HTTP
  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data)) // Résoudre la promesse avec les données de la réponse
        .catch((ex) => reject(ex.response.data)); // Rejeter la promesse avec les données de l'erreur de la réponse
    });
  }
}

// Création d'une instance unique de la classe HttpService
const httpService = new HttpService();

// Exportation de l'instance unique pour une utilisation dans d'autres parties de l'application
export default httpService;
