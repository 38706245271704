import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'Theme/base.scss'; // Import du fichier style.css
//import 'Theme/base.css'; // Import du fichier style.css
import 'Theme/contact.scss'
import 'Theme/portfolio.scss';  // Import du fichier de style SASS

import 'Theme/footer.scss'

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>

);

reportWebVitals();
