import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, ConfigProvider } from 'antd';
import { competenceColumns } from 'component/Value/TableData/CompetenceData/CompetenceTableData';
import useCompetenceData from 'component/Data/CompetenceData/useCompetenceData';
import TableSection from 'component/Value/Table/TableSection';
import CompetenceModal from 'component/Modal/CompetenceModal';
import { TinyColor } from '@ctrl/tinycolor';
import i18n from 'I18n/index';

const Container = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
`;

const deletedColors = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
const upgradeColors = ['#6253E1', '#04BEFE'];

const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());

const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const Competence = () => {
  const { loading, data, createCompetence, updateCompetence, deleteCompetence } = useCompetenceData();
  const [visible, setVisible] = useState(false);
  const [selectedCompetence, setSelectedCompetence] = useState(null);

  const showModal = (competence = null) => {
    setSelectedCompetence(competence);
    setVisible(true);
  };

  const closeModal = () => {
    setSelectedCompetence(null);
    setVisible(false);
  };

  const handleSubmit = async (competenceData) => {
    if (selectedCompetence) {
      try {
        await updateCompetence(selectedCompetence.id, competenceData);
      } catch (error) {
        // Error messages are handled within useCompetenceData
      }
    } else {
      try {
        await createCompetence(competenceData);
      } catch (error) {
        // Error messages are handled within useCompetenceData
      }
    }
    closeModal();
  };

  const handleDelete = async (record) => {
    if (record && record.id) {
      try {
        await deleteCompetence(record.id);
      } catch (error) {
        // Error messages are handled within useCompetenceData
      }
    } else {
      message.error(i18n.t('errors.selectD'));
    }
  };

  const competences = data ? data.competences : [];

  return (
    <Container>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
        {i18n.t('button.new')}
      </Button>
      <TableSection
        title={i18n.t('title.competence')}
        dataSource={competences}
        columns={[
          ...competenceColumns,
          {
            title: i18n.t('title.action'),
            key: 'action',
            render: (_, record) => (
              <span>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg,  ${upgradeColors.join(', ')})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(upgradeColors).join(', ')})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(upgradeColors).join(', ')})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button onClick={() => showModal(record)} type="primary" size="large">
                    {i18n.t('button.update')}
                  </Button>
                </ConfigProvider>
                {' | '}
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg,  ${deletedColors.join(', ')})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(deletedColors).join(', ')})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(deletedColors).join(', ')})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button onClick={() => handleDelete(record)} type="primary" size="large">
                    {i18n.t('button.delete')}
                  </Button>
                </ConfigProvider>
              </span>
            ),
          },
        ]}
        loading={loading}
      />
      <CompetenceModal
        visible={visible}
        onClose={closeModal}
        initialValues={selectedCompetence}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default Competence;
