import { useState, useEffect } from 'react';
import { Alert, Spin, message } from 'antd';
import axios from 'axios';
import { baseURL } from 'Ressources'; // Assurez-vous que baseURL est défini correctement
import Contact from 'views/Contact';
import ImageRenderer from 'asset/ImageRenderer';
import i18n from 'I18n/index';
import CookieModal from 'component/Modal/CookieModal'; // Ajustez le chemin d'importation si nécessaire
import { useCookies } from 'react-cookie'; // Importer le hook pour les cookies

const Presentation = () => {
    const [loading, setLoading] = useState(false);
    const [presentationData, setPresentationData] = useState({ presentations: [] });
    const [error, setError] = useState(null);
    const [cookies, setCookie] = useCookies(['consent']); // Gérer les cookies
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchPresentationData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseURL}/presentation`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true, // Si vous avez besoin d'envoyer des cookies avec la requête
            });
            if (response.status === 200) {
                const presentations = response.data;
                setPresentationData({ presentations });
            } else {
                throw new Error(i18n.t('errors.data.default'));
            }
        } catch (error) {
            setError(i18n.t('errors.data.no_data'));
            message.error(i18n.t('errors.data.default'));
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!cookies.consent) {
            setIsModalVisible(true); // Affiche le modal si le consentement n'a pas été donné
        } else {
            fetchPresentationData(); // Charge les données de présentation si le consentement est déjà donné
        }
    }, [cookies]);

    const splitDescription = (description) => {
        const quarterLength = Math.floor(description.length / 9);
        const firstPart = description.slice(0, quarterLength);
        const secondPart = description.slice(quarterLength, quarterLength * 4.05);
        const thirdPart = description.slice(quarterLength * 4.05, quarterLength * 5.9);
        const fourthPart = description.slice(quarterLength * 5.9);
        return { firstPart, secondPart, thirdPart, fourthPart };
    };

    const handleCookieModalClose = () => {
        setIsModalVisible(false);
        fetchPresentationData(); // Charge les données de présentation après la fermeture du modal
    };

    return (
        <div className="base-container">
            <CookieModal visible={isModalVisible} onClose={handleCookieModalClose} />
            
            {loading ? (
                <Spin spinning={true} />
            ) : error ? (
                <Alert message="Erreur" description={error} type="error" showIcon />
            ) : (
                <section className="base-section">
                    <Contact />

                    <h2 className="base-section-header">{i18n.t('title.presentation')}</h2>
                    <div className="two-column-container">
                        <div className="left-column">
                            <ul className="base-list">
                                {presentationData.presentations.map((presentation, index) => {
                                    const { firstPart, secondPart, thirdPart, fourthPart } = splitDescription(presentation.description);
                                    return (
                                        <li key={index} className="base-item">
                                            <ImageRenderer imageName={presentation.img} alt={presentation.img} />

                                            {presentation.nom}

                                            <p className="base-item-description">{firstPart}</p>
                                            <p className="base-item-description">{secondPart}</p>
                                            <p className="base-item-description">{thirdPart}</p>
                                            <p className="base-item-description">{fourthPart}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default Presentation;
