import React, { useState } from 'react';
import { Modal, Button, Checkbox, Form } from 'antd';
import { useCookies } from 'react-cookie';

const CookieModal = ({ visible, onClose }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['consent']);
    const [essential, setEssential] = useState(cookies.essential === 'true');
    const [marketing, setMarketing] = useState(cookies.marketing === 'true');
    const [analytics, setAnalytics] = useState(cookies.analytics === 'true');

    const handleAcceptAll = () => {
        setCookie('consent', 'all', { path: '/', maxAge: 3600 });
        setCookie('essential', 'true', { path: '/', maxAge: 3600 });
        setCookie('marketing', 'true', { path: '/', maxAge: 3600 });
        setCookie('analytics', 'true', { path: '/', maxAge: 3600 });
        onClose(); // Ferme le modal
    };

    const handleSavePreferences = () => {
        setCookie('consent', 'preferences', { path: '/', maxAge: 3600 });
        setCookie('essential', essential ? 'true' : 'false', { path: '/', maxAge: 3600 });
        setCookie('marketing', marketing ? 'true' : 'false', { path: '/', maxAge: 3600 });
        setCookie('analytics', analytics ? 'true' : 'false', { path: '/', maxAge: 3600 });
        onClose(); // Ferme le modal
    };

    const handleDecline = () => {
        setCookie('consent', 'none', { path: '/', maxAge: 3600 });
        removeCookie('essential', { path: '/' });
        removeCookie('marketing', { path: '/' });
        removeCookie('analytics', { path: '/' });
        onClose(); // Ferme le modal
    };

    return (
        <Modal
            open={visible}
            title="Gestion des Cookies"
            onCancel={onClose}
            footer={[
                <Button key="decline" onClick={handleDecline}>
                    Refuser
                </Button>,
                <Button key="save" type="primary" onClick={handleSavePreferences}>
                    Sauvegarder les Préférences
                </Button>,
                <Button key="accept" type="primary" onClick={handleAcceptAll}>
                    Accepter Tout
                </Button>,
            ]}
        >
            <p>Nous utilisons des cookies pour améliorer votre expérience. Vous pouvez choisir les types de cookies que vous acceptez :</p>
            <Form>
                <Form.Item>
                    <Checkbox
                        checked={essential}
                        onChange={(e) => setEssential(e.target.checked)}
                    >
                        Cookies Essentiels (nécessaires au fonctionnement du site)
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={marketing}
                        onChange={(e) => setMarketing(e.target.checked)}
                    >
                        Cookies de Marketing (pour des publicités personnalisées)
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={analytics}
                        onChange={(e) => setAnalytics(e.target.checked)}
                    >
                        Cookies d'Analyse (pour améliorer notre site en fonction de vos visites)
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CookieModal;
