import { useReducer, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { baseAdminUrl } from 'Ressources';
import i18n from 'I18n/index';

// Actions
const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const DELETE_SUCCESS = 'DELETE_SUCCESS';

// Initial State
const initialState = {
  loading: false,
  data: {
    formations: [],
  },
  error: null,
};

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return { ...state, loading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_ERROR:
      return { ...state, loading: false, error: action.error };
    case CREATE_SUCCESS:
      return { ...state, data: { ...state.data, formations: [...state.data.formations, action.payload] } };
    case UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          formations: state.data.formations.map((comp) =>
            comp.id === action.payload.id ? action.payload : comp
          ),
        },
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          formations: state.data.formations.filter((comp) => comp.id !== action.payload.id),
        },
      };
    default:
      return state;
  }
};

// Custom Hook
const useFormationData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: FETCH_START });
      try {
        const response = await axios.get(`${baseAdminUrl}/formation/`);
        if (response.status === 200) {
          const formations = Array.isArray(response.data) ? response.data : [];
          dispatch({ type: FETCH_SUCCESS, payload: { formations } });
        } else {
          throw new Error(i18n.t('error.formation.fetch'));
        }
      } catch (error) {
        dispatch({ type: FETCH_ERROR, error:i18n.t('error.formation.fetch')});
        message.error(i18n.t('error.formation.fetch'));
      }
    };

    fetchData();
  }, []);

  const createFormation = async (formationData) => {
    try {
      const response = await axios.post(`${baseAdminUrl}/formation/ajouter`, formationData);
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: CREATE_SUCCESS, payload: response.data });
        message.success(i18n.t('success.formation.create'));
      } else {
        throw new Error(i18n.t('error.formation.create'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updateFormation = async (id, formationData) => {
    try {
      const requestBody = new URLSearchParams(formationData).toString();

      const response = await axios.put(`${baseAdminUrl}/formation/modifier/${id}`, requestBody, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': requestBody.length
        }
      });
      if (response.status === 200) {
        dispatch({ type: UPDATE_SUCCESS, payload: response.data });
        message.success(i18n.t('success.formation.update'));
      } else {
        throw new Error(i18n.t('error.formation.update'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };


  const deleteFormation = async (id) => {
    try {
      const response = await axios.delete(`${baseAdminUrl}/formation/supprimer/${id}`);
      if (response.status === 200) {
        dispatch({ type: DELETE_SUCCESS, payload: { id } });
        message.success(i18n.t('success.formation.delete'));
      } else {
        throw new Error(i18n.t('error.formation.delete'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return { ...state, createFormation, updateFormation, deleteFormation };
};

export default useFormationData;
