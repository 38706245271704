import { useState, useEffect } from 'react';
import { Spin, message, Alert } from 'antd';
import axios from 'axios';
import { baseURL } from 'Ressources'; // Assurez-vous que baseURL est défini correctement
import ImageRenderer from 'asset/ImageRenderer';
import Copyright from 'views/Copyright';
import i18n from 'I18n/index';

const Footer = () => {
    const [loading, setLoading] = useState(false);
    const [footerData, setFooterData] = useState({ footers: [] });
    const [error, setError] = useState(null);

    const fetchFooterData = async () => {
        setLoading(true);
        setError(null);  // Réinitialiser l'erreur au début de la nouvelle requête
        try {
            const response = await axios.get(`${baseURL}/footer`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true, // Si vous avez besoin d'envoyer des cookies avec la requête
            });
            if (response.status === 200) {
                const footers = response.data;
                setFooterData({ footers });
            } else {
                throw new Error(i18n.t('errors.data.default'));
            }
        } catch (error) {
            setError(i18n.t('errors.data.no_data'));
            message.error(i18n.t('errors.data.default'));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFooterData();
    }, []);

    return (
        <div className="base-container">
            {loading ? (
                <Spin spinning={true} />
            ) : error ? (
                <Alert message="Erreur" description={error} type="error" showIcon />
            ) : (
                <section className="base-section">
                    <div className="footer-flex-container">
                        {footerData.footers.length > 0 ? (
                            footerData.footers.map((footer, index) => (
                                <div key={index} className="footer-item">
                                    <h4>{footer.titre}</h4>
                                    <p className="footer-item-description">
                                        <a href={footer.lien}>
                                        <ImageRenderer imageName={footer.img} alt={footer.titre} /></a>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p>{i18n.t('pages.no_data')}</p>
                        )}
                    </div>
                    <Copyright /> 
                </section>
            )}
           
        </div>
    );
};

export default Footer;
