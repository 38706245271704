import React, { useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import i18n from 'I18n/index';

const Logout = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(i18n.t('logout.loading'));

  const [messageType, setMessageType] = useState("loading"); // Peut être "loading", "success", ou "error"

  useEffect(() => {
    const handleLogout = async () => {
      try {
        // Simuler une opération de déconnexion asynchrone
        await new Promise((resolve) => setTimeout(resolve, 3000));
        
        // Réinitialiser le token ou faire d'autres opérations de nettoyage
        setToken(null);

        // Mettre à jour le message et le type après la déconnexion
        setMessage(i18n.t('logout.success.200'));
        setMessageType("success");
        setLoading(false);

        // Rediriger après un court délai pour permettre au message de s'afficher
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1000); // Attendre 1 seconde avant la redirection
      } catch (error) {
        console.error("Erreur lors de la déconnexion : ", error);
        setMessage(i18n.t('logout.errosr.messageE'));
        setMessageType("error");
        setLoading(false);
      }
    };

    handleLogout();

    return () => {
      // Ici, vous pouvez nettoyer toute opération en cours si nécessaire
    };
  }, [navigate, setToken]);

  return (
    <div>
      <p>{message}</p>
    </div>
  );
};

export default Logout;
