import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import i18n from 'I18n/index';

const UploadFile = ({ onChange }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      onChange(selectedFile); // Appel de la fonction onChange fournie par le parent
    }
  };

  return (
    <div>
      <input type="file" accept=".pdf" onChange={handleFileChange} style={{ display: 'none' }} />
      <Button icon={<PlusOutlined />} onClick={() => document.querySelector('input[type="file"]').click()}>
      {i18n.t('upload.file')}
      </Button>
      {file && <p>{i18n.t('upload.select')} : {file.name}</p>}
    </div>
  );
};

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default UploadFile;
