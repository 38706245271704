import React from 'react';
import styled from 'styled-components';
import { competenceColumns } from 'component/Value/TableData/CompetenceData/CompetenceTableData';
import { experienceColumns } from 'component/Value/TableData/ExperienceData/ExperienceTableData';
import { formationColumns } from 'component/Value/TableData/FormationData/FormationTableData';
import { presentationColumns } from 'component/Value/TableData/PresentationData/PresentationTableData';
import { portfolioColumns } from 'component/Value/TableData/PortfolioData/PortfolioTableData';
import useDashboardData from 'component/Data/DashboardData/useDashboardData';
import TableSection from 'component/Value/Table/TableSection';
import i18n from 'I18n/index';

const Container = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
`;

const Dashboard = () => {
  const { loading, data } = useDashboardData();
  return (
    <Container>
      <TableSection
        title={i18n.t('title.competence')}
        dataSource={data.competences}
        columns={competenceColumns}
        loading={loading}
      />
      <TableSection
        title={i18n.t('title.experience')}
        dataSource={data.experiences}
        columns={experienceColumns}
        loading={loading}
      />
      <TableSection
        title={i18n.t('title.formation')}
        dataSource={data.formations}
        columns={formationColumns}
        loading={loading}
      />
      <TableSection
        title={i18n.t('title.presentation')}
        dataSource={data.presentation}
        columns={presentationColumns}
        loading={loading}
      />
      <TableSection
        title={i18n.t('title.portfolio')}
        dataSource={data.portfolio}
        columns={portfolioColumns}
        loading={loading}
      />
    </Container>
  );
};

export default Dashboard;
