import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message, InputNumber } from 'antd';
import useExperienceData from 'component/Data/ExperienceData/useExperienceData';
import i18n from 'I18n/index';

const ExperienceModal = ({ visible, onClose, initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { createExperience, updateExperience } = useExperienceData();
    const { TextArea } = Input;

    useEffect(() => {
        form.resetFields();
        if (initialValues) {
            form.setFieldsValue({
                nom: initialValues.nom,
                poste: initialValues.poste,

                lieu: initialValues.lieu,
                description: initialValues.description,
                mois_debut: initialValues.mois_debut,
                annee_debut: initialValues.annee_debut,
                mois_fin: initialValues.mois_fin,
                annee_fin: initialValues.annee_fin,
            });
        }
    }, [form, initialValues]);


    const handleFinish = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('nom', values.nom);
        formData.append('poste', values.poste);
        formData.append('lieu', values.lieu);
        formData.append('description', values.description);
        formData.append('mois_debut', values.mois_debut);
        formData.append('annee_debut', values.annee_debut);
        formData.append('mois_fin', values.mois_fin);
        formData.append('annee_fin', values.annee_fin);

        try {
            if (initialValues && initialValues.id) {
                await updateExperience(initialValues.id, formData);
            } else {
                await createExperience(formData);
            }
            onClose();
        } catch (error) {
            message.error(i18n.t('error.experience.save'));
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal
            open={visible}
            title={initialValues ? i18n.t('pages.experience.form.edit') : i18n.t('pages.experience.form.add')}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {i18n.t('button.cancel')}
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
                    {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item name="id" label="ID" hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="nom"
                    label={i18n.t('pages.experience.table.compagny')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.compagny') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="poste"
                    label={i18n.t('pages.experience.table.poste')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.poste') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="lieu"
                    label={i18n.t('pages.experience.table.lieu')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.lieu') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={i18n.t('pages.experience.table.description')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.description') }]}
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="mois_debut"
                    label={i18n.t('pages.experience.table.debutM')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.debutM') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="annee_debut"
                    label={i18n.t('pages.experience.table.debutA')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.debutA') }]}
                >
                    <InputNumber min={2000} max={2100} />
                </Form.Item>
                <Form.Item
                    name="mois_fin"
                    label={i18n.t('pages.experience.table.finM')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.finM') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="annee_fin"
                    label={i18n.t('pages.experience.table.finA')}
                    rules={[{ required: true, message: i18n.t('pages.experience.placeholder.finA') }]}
                >
                    <InputNumber min={2000} max={2100} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

ExperienceModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        nom: PropTypes.string,
        poste: PropTypes.string,
        description: PropTypes.string,
        lieu: PropTypes.string,
        mois_debut: PropTypes.string,
        annee_debut: PropTypes.string,
        mois_fin: PropTypes.string,
        annee_fin: PropTypes.string,
    }),
};

ExperienceModal.defaultProps = {
    initialValues: null,
};

export default ExperienceModal;
