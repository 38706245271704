import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "views/Admin/Dashboard";
import CV from "views/CV";
import Portfolio from "views/Portfolio";
import Login from "views/Login";
import Logout from "views/Admin/Logout";
import Presentation from "views/Presentation";
import Presentations from "views/Admin/Presentation";
import ProtectedRoute from "./ProtectedRoute";
import CompetencePro from "views/Admin/Competence";
import Portfolios from "views/Admin/Portfolio";
import Experience from "views/Admin/Experience";
import Formation from "views/Admin/Formation";
import Contact from "views/Admin/Contact";
import Footer from "views/Admin/Footer";

// Configuration des routes
const NavRoute = () => {
  return (
    <Routes>
      {/* Route publique pour la présentation */}
      <Route path="/" element={<Presentation />} />

      {/* Routes publiques pour le CV, le portfolio, la connexion et la déconnexion */}
      <Route path="/cv" element={<CV />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      {/* Routes protégées pour le tableau de bord, le profil, le CV et le portfolio de l'admin */}
      <Route path="/admin/home" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/admin/Presentation" element={<ProtectedRoute element={<Presentations />} />} />
      <Route path="/admin/Competence" element={<ProtectedRoute element={<CompetencePro />} />} />
      <Route path="/admin/portfolio" element={<ProtectedRoute element={<Portfolios />} />} />
      <Route path="/admin/experience" element={<ProtectedRoute element={<Experience />} />} />
      <Route path="/admin/formation" element={<ProtectedRoute element={<Formation />} />} />
      <Route path="/admin/contact" element={<ProtectedRoute element={<Contact />} />} />
      <Route path="/admin/footer" element={<ProtectedRoute element={<Footer />} />} />

    </Routes>
  );
};

export default NavRoute;
